import axios from '@axios'

import qs from 'qs'

const prefixPath = '/api/product/attr'
export default {

  saveOrUpdate(data) {
    return axios.post(`${prefixPath}/saveOrUpdate`, data)
  },

  toggleEnabled(data) {
    return axios.post(`${prefixPath}/toggleEnabled`, data)
  },

  getPages(data) {
    return axios.post(`${prefixPath}/getPages`, data)
  },

  delete(productAttrId) {
    return axios.post(`${prefixPath}/delete`, qs.stringify({
      productAttrId,
    }))
  },
  listAll() {
    return axios.get(`${prefixPath}/listAll`)
  },

}
