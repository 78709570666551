<template>
  <div v-if="$can('product_attr_list','product_attr')">
    <v-alert
      text
      dense
      color="warning"
      border="left"
    >
      <span class="text-body-2">导航设置用于首页快捷导航</span>
    </v-alert>
    <v-card
      id="holiday-common"
    >
      <v-card-title
        v-if="$can('product_attr_save','product_attr')"
        class="d-flex align-center flex-wrap pb-0"
      >
        <div class="d-flex align-center pb-5">
          <!-- create invoice -->
          <v-btn
            color="primary"
            class="me-3"
            @click="editItem()"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新增</span>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            itemsPerPageAllText: '全部',
            itemsPerPageText: '每页显示 ',
            showCurrentPage: true
          }"
          disable-filtering
          :headers="getHeader()"
          :items="productAttrs"
          :items-per-page="pages.itemsPerPage"
          :server-items-length="pages.serverItemsLength"
          no-data-text="无数据"
          @pagination="loadPage"
        >
          <template
            #[`item.isEnabled`]="{ item }"
          >
            <v-switch
              v-model="item.isEnabled"
              dense
              x-small
              :label="item.isEnabled ? `显示`: `禁用`"
              @change="toggleEnabled(item)"
            ></v-switch>
          </template>
          <!-- action -->
          <template #[`item.actions`]="{ item }">
            <v-btn
              v-if="$can('product_attr_manage','product_attr')"
              text
              dense
              x-small
              color="primary"
              @click="toProductManage(item)"
            >
              管理商品
            </v-btn>
            <v-btn
              v-if="$can('product_attr_update','product_attr')"
              text
              dense
              x-small
              color="primary"
              @click="editItem(item)"
            >
              编辑
            </v-btn>
            <v-btn
              v-if="$can('product_attr_delete','product_attr')"
              text
              dense
              x-small
              color="primary"
              @click="showDeleteDialog(item)"
            >
              删除
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>

      <v-dialog
        v-model="deleteDialog"
        width="300"
      >
        <v-card>
          <v-card-text> 删除<span style="color: red">{{ deleteData.name }}</span>, 将清空该导航的所以商品关系 </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="deleteItem()"
            >
              确认删除
            </v-btn>
            <v-btn
              color="normal"
              text
              @click="deleteDialog = false"
            >
              取消
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer
        v-model="editDialog"
        temporary
        touchless
        :right="!$vuetify.rtl"
        :width="$vuetify.breakpoint.smAndUp ? 750 : '100%'"
        app
      >
        <v-card height="100%">
          <div class="drawer-header d-flex align-center">
            <span class="font-weight-semibold text-base text-h6">{{ editedItem.id ? '编辑' : '添加' }}商品导航</span>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mr-5"
              :disabled="commitFlag"
              :loading="commitFlag"
              @click="handleFormSubmit()"
            >
              保存
            </v-btn>
            <v-btn
              icon
              small
              @click="editDialog = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </div>

          <v-card-text
            style="background-color: #F4F5FA; height: 90%; padding-top: 20px;"
            class="overflow-auto"
          >
            <v-card>
              <v-form
                ref="editForm"
                lazy-validation
                @submit.prevent="handleFormSubmit"
              >
                <v-card-text class="pa-3">
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="name"><span style="color: red">*</span> 导航名称：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-text-field
                          id="name"
                          v-model="editedItem.name"
                          :rules="rules.required"
                          required
                          counter="25"
                          label="商品导航名称"
                          outlined
                          clearable
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                      >
                        <span style="color: red">*</span>上传图片
                      </v-col>

                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-file-input
                          v-model="currentImg"
                          :rules="editedItem.img ? [] : rules.img"
                          :loadings="loadings.bannerUploaderLoading"
                          :disabled="loadings.bannerUploaderLoading"
                          dense
                          outlined
                          @change="uploadImg"
                        ></v-file-input>
                        <v-img
                          v-if="editedItem.img"
                          :src="editedItem.img"
                          class="mt-1 ml-3"

                          max-height="150"
                          max-width="250"
                        ></v-img>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                      >
                        <label for="sort"><span style="color: red">*</span>排序：</label>
                      </v-col>
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-text-field
                          id="orderNum"
                          v-model="editedItem.sort"
                          :rules="rules.required"
                          label="排序"
                          type="Number"
                          outlined
                          clearable
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-form>
            </v-card>
          </v-card-text>
        </v-card>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<script>

import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
} from '@mdi/js'
import productAttrApi from '@/api/product/productAttr'
import uploadImg from '@/api/upload'

export default {
  name: 'MemberLevel',
  components: {
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
      },
      editDialog: false,
      deleteDialog: false,
      rules: {
        required: [v => !!v || '该字段不能为空'],
        img: [value => !value || value.size < 2 * 1024 * 1024 || '图片大小不能超过2M'],
      },
      loadings: {
        submitLoading: false,
        bannerUploaderLoading: false,

      },
      productAttrs: [],
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      editedItem: {},
      editedIndex: -1,
      defaultItem: {
        id: undefined,
        name: '',
        sort: 1,
      },
      deleteData: {},
      currentImg: {},
      commitFlag: false,
    }
  },
  methods: {
    getHeader() {
      const baseHeader = [
        { text: '导航名称', value: 'name' },
        { text: '排序', value: 'sort' },
        { text: '商品数量', value: 'productCount' },
        { text: '创建时间', value: 'createdTime' },

      ]
      if (this.$can('product_attr_enabled', 'product_attr')) {
        baseHeader.push({ text: '显示', value: 'isEnabled' })
      }

      if (this.$can('product_attr_update', 'product_attr')
          || this.$can('product_attr_delete', 'product_attr')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    loadPage({ page, itemsPerPage }) {
      const searchData = {
        rows: itemsPerPage,
        page,
      }
      productAttrApi.getPages(searchData)
        .then(response => {
          const { data } = response.data
          this.productAttrs = data.records
          if (data.size === -1) {
            this.pages.serverItemsLength = this.productAttrs.length
          } else {
            this.pages.serverItemsLength = data.total
          }
        })
    },
    editItem(item) {
      if (item) {
        this.editedIndex = this.productAttrs.indexOf(item)

        this.editedItem = item
      } else {
        this.editedIndex = -1
        this.initEditedItem()
        this.$nextTick(() => {
          this.$refs.editForm.resetValidation()
        })
      }
      this.commitFlag = false
      this.editDialog = true
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return

      if (this.commitFlag) {
        return
      }

      this.loadings.submitLoading = true
      const submitData = {
        ...this.editedItem,
      }

      productAttrApi.saveOrUpdate(submitData)
        .then(response => {
          this.$toast.success('保存成功')
          if (this.editedIndex > -1) {
            Object.assign(this.productAttrs[this.editedIndex], this.editedItem)
          } else {
            this.loadPage(this.pages)
          }
          this.commitFlag = false
          this.editDialog = false
        })
        .finally(() => {
          this.currentImg = {}
          this.editedItem.img = ''
          this.$refs.editForm.resetValidation()
          this.loadings.submitLoading = false
        })
    },
    showDeleteDialog(item) {
      this.deleteData = item
      this.deleteDialog = true
    },
    deleteItem() {
      productAttrApi
        .delete(this.deleteData.productAttrId)
        .then(() => {
          this.$toast.success('删除成功！')
          this.deleteData = {}
          this.loadPage(this.pages)
          this.deleteDialog = false
        })
    },
    toggleEnabled(item) {
      const saveData = {
        productAttrId: item.productAttrId,
        isEnabled: item.isEnabled,
      }
      productAttrApi.toggleEnabled(saveData).then(res => {
        this.$toast.success('修改成功！')
      })
    },
    toProductManage(item) {
      this.$router.push({ path: '/product', query: { productAttrId: item.productAttrId } })
    },
    uploadImg(blob) {
      // 发送请求上传到七牛云，将图片地址返回到这里
      this.loadings.bannerUploaderLoading = true
      uploadImg.uploadImg(blob).then(res => {
        this.editedItem.img = res.data.data
      }).finally(() => {
        this.loadings.bannerUploaderLoading = false
      })
    },
    initEditedItem() {
      this.defaultItem.id = ''
      this.defaultItem.name = ''
      this.currentImg = {}
      this.defaultItem.sort = null
      this.editedItem = this.defaultItem
    },
  },
}
</script>

<style scoped>

</style>
