import axios from '@axios'

// import qs from 'qs'

export default {
  uploadImg(file) {
    const param = new FormData()

    // 通过append向form对象添加数据
    param.append('file', file)

    return axios.post('/api/upload/images', param, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  uploadVideo(file) {
    const param = new FormData()

    // 通过append向form对象添加数据
    param.append('file', file)

    return axios.post('/api/upload/video', param, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
}
